import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { authHeader } from "src/utils/authHeader";
import { useParams, Link } from "react-router-dom";
import { AddTestQuestion } from "src/components";
import QuestionCard from "../../components/QuestionCard/QuestionCard";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const TeacherTestInner = () => {
  document.title = "Тесты | EasyENT";
  const params = useParams();
  const [testQuqestions, setTestQuqestions] = useState<any>([]);
  const [uploadPhoto, setUploadPhoto] = useState({ photofile: "string" });
  const [subjectTitle, setSubjectTitle] = useState<string>("");

  useEffect(() => {
    axios
      .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader() })
      .then((response: any) => {
        const sorted = [...response.data.questions].sort(
          (a, b) => a.question.sequence - b.question.sequence
        );
        setTestQuqestions(sorted);
      });
    axios
      .get(`/api/v1/tests/tests/${params.id}`, { headers: authHeader() })
      .then((response: any) => {
        setSubjectTitle(response.data.subject.title);
      });
  }, [uploadPhoto]);
  console.log(testQuqestions);
  const UploadQuestionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post("/api/v1/tests/question/" + question_id + "/image", formdata, {
          headers: authHeader(),
        })
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };

  const onChangeChoiceValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;

    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    const index: number = testQuqestions.findIndex(
      (questions: any) => questions.id === questionIdnumber
    );
    const name = testQuqestions[index]["display_name"];
    const myobj = {
      answer: element.getAttribute("data-value"),
      display_name: name,
      sequence: parseInt("" + element.getAttribute("data-sequence")),
      question_type: "choice",
    };
    axios
      .put("/api/v1/question/" + questionIdnumber, myobj, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };
  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_choise_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/tests/variant/" + question_choise_id + "/image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };

  const getLessonQuestions = () => {
    axios
      .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader() })
      .then((response) => {
        const sorted = [...response.data.questions].sort(
          (a, b) => a.question.sequence - b.question.sequence
        );
        setTestQuqestions(sorted);
      });
  };

  const onUpdateQuestion = (question: any) => {
    const newQuestion = {
      display_name: question.display_name,
    };
    axios
      .put("/api/v1/tests/question/" + question.id, newQuestion, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const onUpdateQuestionSequence = async (
    questionId: number,
    sequence: number
  ) => {
    const newQuestion = {
      sequence,
    };
    await axios
      .put("/api/v1/tests/question/" + questionId, newQuestion, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const DeleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget;
    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    axios
      .delete("/api/v1/tests/question/" + questionIdnumber, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 204 || response.status == 200) {
          alert("Вопрос успешно удален");
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onUpdateChoice = (choice: any) => {
    axios
      .put("/api/v1/tests/variant/" + choice.id, choice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setTestQuqestions((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((question: any, index: number, t: any) => {
    return (
      <QuestionCard
        index={index}
        question={question}
        key={question.id}
        id={question.question.id}
        moveCard={moveCard}
        onChangeChoice={onChangeChoiceValue}
        onUploadPhoto={UploadPhoto}
        onDeleteQuestion={DeleteQuestion}
        onUpdateChoice={onUpdateChoice}
        testQuqestions={t}
        setTestQuqestions={setTestQuqestions}
        onUpdateQuestion={onUpdateQuestion}
        onUploadQuestionPhoto={UploadQuestionPhoto}
        onUpdateQuestionSequence={onUpdateQuestionSequence}
      />
    );
  }, []);

  // const DeleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   const element = e.currentTarget;
  //   const questionId = element.getAttribute("data-quesiton-id");
  //   const questionIdnumber = parseInt("" + questionId);
  //   axios
  //     .delete("/api/v1/tests/question/" + questionIdnumber, {
  //       headers: authHeader(),
  //     })
  //     .then((response) => {
  //       if (response.status == 204 || response.status == 200) {
  //         alert('Вопрос успешно удален'),
  //         window.location.reload();
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error)
  //     });
  // };
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои тетсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/admin/tests/${params.id}`}>{subjectTitle}</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>Редактирование теста</span>
        </li>
      </ul>
      <h1 className="title"></h1>
      <AddTestQuestion
        questionlength={testQuqestions.length ? testQuqestions.length : "0"}
        testid={params.id}
      />
      <h1>Cписок вопросов:</h1>
      <ul className="qadmin-list">
        <DndProvider backend={HTML5Backend}>
          {testQuqestions.map((question: any, index: any) =>
            renderCard(question, index, testQuqestions)
          )}
        </DndProvider>
      </ul>
    </div>
  );
};
