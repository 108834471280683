import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { IPenalty, ICourse,  ILesson, User_feeds, OverallHomework } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { MyLessonItem } from "src/components";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useAppSelector } from "src/hooks/redux";
import { Penalty } from "src/components";

export const ViewCoursePage2 = () => {
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [Novlessons2, setNov2Lessons] = useState<ILesson[]>([]);
  const [Declessons2, setDec2Lessons] = useState<ILesson[]>([]);
  const [Janlessons2, setJan2Lessons] = useState<ILesson[]>([]);
  const [Feblessons2, setFeb2Lessons] = useState<ILesson[]>([]);
  const [penalties, setPenalties] = useState<IPenalty[]>([]);
  const [lessonCompleted, setLessonCompleted] = useState<any>([]);
  const [User_feeds, setUser_feeds] = useState<User_feeds[]>([]);
  const [novOverallHomework, setNovOverallHomework] = useState<OverallHomework[]>([]);
  const [decOverallHomework, setDecOverallHomework] = useState<OverallHomework[]>([]);
  const [JanOverallHomework, setJanOverallHomework] = useState<OverallHomework[]>([]);
  const [FebOverallHomework, setFebOverallHomework] = useState<OverallHomework[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const [activeTab, setActiveTab] = useState('1');
  const dateRanges = [
    {
      start: new Date("2024-09-11"),
      end: new Date("2024-10-10"),
    },
    {
      start: new Date("2024-10-11"),
      end: new Date("2024-11-10"),
    },
    {
      start: new Date("2024-11-11"),
      end: new Date("2024-12-10"),
    },
    {
      start: new Date("2024-12-11"),
      end: new Date("2025-01-10"),
    },
    {
      start: new Date("2025-01-11"),
      end: new Date("2025-02-10"),
    },
    {
      start: new Date("2025-02-11"),
      end: new Date("2025-03-10"),
    },
  ];
  const normalizeDate = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const todayNormalized = normalizeDate(new Date());
  
  useEffect(() => {
    const currentTabIndex = dateRanges.findIndex(
      (range) => todayNormalized >= normalizeDate(range.start) && todayNormalized <= normalizeDate(range.end)
    );
    if (currentTabIndex !== -1) {
      setActiveTab('' + (currentTabIndex + 1));
    }
  }, []);

  function getCompletedLessons() {
    axios
      .get(`api/v1/courses/${params?.id}/lesson_completed_count`, {
        headers: authHeader(),
      })
      .then((res: any) => setLessonCompleted(res?.data));
  }
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("Tab changed to: ", newValue);
    setActiveTab(newValue);
  };
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
    axios
      .get("/api/v1/penalty/?skip=0&user_id=" + user.id + "&course_id=" +
          params.id, { headers: authHeader() })
      .then((response) => {
        setPenalties(response.data);
    });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-11-11&to_date=2024-12-14&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setNov2Lessons(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-12-11&to_date=2025-01-11&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setDec2Lessons(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2025-01-11&to_date=2025-02-11&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setJan2Lessons(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2025-02-11&to_date=2025-03-11&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setFeb2Lessons(response.data);
      });          
    axios
      .get(
        "/api/v1/user_feed/?skip=0&user_id=" +
          user.id +
          "&course_id=" +
          params.id,
        { headers: authHeader() }
      )
      .then((response) => {
        setUser_feeds(response.data);
      });
    getCompletedLessons();
  }, []);
  
    useEffect(() => {
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-11-11&end_date=2024-12-10&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setNovOverallHomework(response.data);
      });
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-12-11&end_date=2025-01-10&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setDecOverallHomework(response.data);
      });
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2025-01-11&end_date=2025-02-10&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setJanOverallHomework(response.data);
      });
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2025-02-11&end_date=2025-03-10&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setFebOverallHomework(response.data);
      });
    }, []);    
  const filterPenaltiesForTab = (rangeIndex: number) => {
      const { start, end } = dateRanges[rangeIndex];
      return penalties.filter((penalty) => {
        const createdAt = new Date(penalty.created_at);
        return createdAt >= start && createdAt <= end;
      });
  };
  return (
    <div className="view-course">
      {course ? (
          <div className="course-info box">
            <div className="course-info__top">
              <span className="course-info__heading">
                {course.name}
              </span>
              <div className="course-info__right">
                <Link className="back-btn" to="/my-courses">
                  <i className="fa-solid fa-arrow-left"></i>Назад
                </Link>
              </div>
            </div>  
            <div className="course-info__bot">
              <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://salebot.site/easyent_start_1?userid=${user.id}`}
                  className="btn btn-primary btn-telegram btn-flex"
                > Телеграм группа
                  <img src="assets/svg/telegram.svg" />
              </a>
              {User_feeds.length && User_feeds[0]['package'] == 'premium' ? 
                <Link to={`/course_${course.id}/analytics2`} className="btn btn-primary btn-flex">
                  Аналитика ошибок <i className="fa-solid fa-angle-right"></i>
                </Link>
                : ""
              }
            </div>
          </div>
        ) : (
        ""
      )}
      <div className="last-lesson">
        {course ? (
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Ноябрь" value="3" />
                <Tab label="Декабрь" value="4" />
                <Tab label="Январь" value="5" />
                <Tab label="Февраль" value="6" />
              </TabList>
            </Box>
            <TabPanel className="lesson-panel" value="3">
              <>
                <div className="month-info">
                  <div className="month-info__row course-penalty">
                    {
                      <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(2)}/>
                    }
                  </div>
                  <div>
                    Общий процент дз: {novOverallHomework.length > 0 && novOverallHomework[0].homework_is_correct_percent ? 
                        `${novOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                      : "0%"
                      }
                  </div>
                </div> 
                {User_feeds.filter((e) => e.start_date === "2024-11-11").length > 0
                  ? 
                  (<ul className="lesson-list"> 
                    {Novlessons2.map((lesson, index) =>
                      lesson.start_date ? (
                          <li key={lesson.id}>
                            <MyLessonItem
                              lesson={lesson}
                              index={index}
                              without_penalty={course ? course.without_penalty : false}
                              completed={lessonCompleted}
                            />
                          </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>)   
                  : "Доступов за ноябрь нету"}
              </>
            </TabPanel>
            <TabPanel className="lesson-panel" value="4">
              <div className="month-info">
                <div className="month-info__row course-penalty">
                  {
                    <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(3)}/>
                  }
                </div>
                <div>
                  Общий процент дз: {decOverallHomework.length > 0 && decOverallHomework[0].homework_is_correct_percent ? 
                      `${decOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                    : "0%"
                    }
                </div>
              </div>
              {User_feeds.filter((e) => e.start_date === "2024-12-11").length > 0  ?
                <>
                  <ul className="lesson-list"> 
                    {Declessons2.map((lesson, index) =>
                      lesson.start_date ? (
                        <li key={lesson.id}>
                          <MyLessonItem
                            lesson={lesson}
                            index={index}
                            without_penalty={course ? course.without_penalty : false}
                            completed={lessonCompleted}
                          />
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </>  
                : (
                  <div className="box">
                    <p>Загрузка или нету доступов</p>  
                  </div>
                )
              }
            </TabPanel>
            <TabPanel className="lesson-panel" value="5">
              <div className="month-info">
                <div className="month-info__row course-penalty">
                  {
                    <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(4)}/>
                  }
                </div>
                <div>
                  Общий процент дз: {JanOverallHomework.length > 0 && JanOverallHomework[0].homework_is_correct_percent ? 
                      `${JanOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                    : "0%"
                    }
                </div>
              </div>
              {User_feeds.filter((e) => e.start_date === "2025-01-11").length > 0  ?
                <>
                  <ul className="lesson-list"> 
                    {Janlessons2.map((lesson, index) =>
                      lesson.start_date ? (
                        <li key={lesson.id}>
                          <MyLessonItem
                            lesson={lesson}
                            index={index}
                            without_penalty={course ? course.without_penalty : false}
                            completed={lessonCompleted}
                          />
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </>  
                : (
                  <div className="box">
                    <p>Загрузка или нету доступов за январь</p>  
                  </div>
                )
              }
            </TabPanel>
            <TabPanel className="lesson-panel" value="6">
              <div className="month-info">
                <div className="month-info__row course-penalty">
                  {
                    <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(5)}/>
                  }
                </div>
                <div>
                  Общий процент дз: {FebOverallHomework.length > 0 && FebOverallHomework[0].homework_is_correct_percent ? 
                      `${FebOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                    : "0%"
                    }
                </div>
              </div>
              {User_feeds.filter((e) => e.start_date === "2025-02-11").length > 0  ?
                <>
                  <ul className="lesson-list"> 
                    {Feblessons2.map((lesson, index) =>
                      lesson.start_date ? (
                        <li key={lesson.id}>
                          <MyLessonItem
                            lesson={lesson}
                            index={index}
                            without_penalty={course ? course.without_penalty : false}
                            completed={lessonCompleted}
                          />
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </>  
                : (
                  <div className="box">
                    <p>Загрузка или нету доступов за январь</p>  
                  </div>
                )
              }
            </TabPanel>
          </TabContext>
          ) : (
            <div className="lesson-panel box">
            Загрузка
            </div>
          )
        }  
      </div>
    </div>
  );
};