import axios from "axios";
import React, { useEffect, useState } from "react";
import {salaryOrder} from "src/interface";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {authHeader} from "src/utils/authHeader";
import {ModalOrderView } from "src/components";


export const TeacherSalary = () => {
  document.title = "Зарплата преподавателей | EasyENT";
  const [allorders, setAllorders] = useState<salaryOrder[]>([]);
  const [yearOrders, setyearOrders] = useState<salaryOrder[]>([]);
  const [activeTab, setActiveTab] = useState('1');
  const [overallSum, setOverallSum] = useState<number>(0);
  const [yearSum, setYearSum] = useState<number>(0);
  const [monthSum, setMonthSum] = useState<number>(0);
  const [overallLength, setOverallLength] = useState<number>(0);
  const [overallYearLength, setOverallYearLength] = useState<number>(0);
  const [overallMonthLength, setOverallMonthLength] = useState<number>(0);
  const [courses, setCourses] = useState<any>([]);
  const [coursesMy, setCoursesMy] = useState<any>([]);
  const [coursesOptions, setcoursesOptions] = useState<any>([]);
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };
  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const courseId = +e.target.value; // Convert the selected value to a number   
    const selectedCourseData = courses.find((course: any) => course.id === courseId);
    if(selectedCourseData.price == 20){
      setActiveTab('3');
    }
    else if(
      selectedCourseData.price == 30
    ){
      setActiveTab('5');
    }
    setSelectedCourse(selectedCourseData); 
  };
  const calculateDate = (tab: number, baseDateString: string) => {
    const baseDate = new Date(baseDateString);
    const number = tab + "";
    const incrementMonths = parseInt(number, 10) - 1;
    baseDate.setMonth(baseDate.getMonth() + incrementMonths);
    return baseDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    axios
      .get("/api/v1/courses?skip=0&limit=100&lang=ru&active=true", { headers: authHeader() })
      .then((response: any) => {
        setCourses(response.data); 
      })
      .catch((error) => console.error("Error fetching courses:", error));
    axios
      .get("/api/v1/courses/my/?skip=0&limit=100&active=true", { headers: authHeader() })
      .then((response: any) => {
        setCoursesMy(response.data); 
      })
      .catch((error) => console.error("Error fetching courses:", error));  
  }, []);
  
  useEffect(() => {
    if (coursesMy.length > 0 && courses.length > 0) {
      const coursesfilteredOptions = courses.filter((course: any) =>
        coursesMy.some((myCourse: any) => myCourse.id === course.id)
      );
      setcoursesOptions(coursesfilteredOptions);
    }
  }, [coursesMy, courses]);
  

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const firstResponse = await axios.get(
          `/api/v1/order/by_course/${selectedCourse.id}?skip=0&limit=500&status=payed&start_date=${selectedCourse.first_start_date}`,
          { headers: authHeader() }
        );
  
        const secondResponse = await axios.get(
          `/api/v1/order/by_course/${selectedCourse.id}?skip=0&limit=500&status=payed&start_date=${selectedCourse.first_end_date}`,
          { headers: authHeader() }
        );
  
        const combinedOrders = [...firstResponse.data, ...secondResponse.data];
        const filteredOrders = combinedOrders.filter((order: salaryOrder) => order.is_full);
        const totalSum = filteredOrders.reduce((sum: number, order: salaryOrder) => {
          const percentage = selectedCourse.description * 0.01 / selectedCourse.duration_month;
          return sum + order.per_amount * percentage;
        }, 0);
  
        const totalLength = filteredOrders.length;
        setYearSum(totalSum);
        setOverallYearLength(totalLength);
        setyearOrders(filteredOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, [selectedCourse]);
  
  
  useEffect(() => {
    if (!selectedCourse) return;
    let tabnumber = parseInt(activeTab, 10);
    if(selectedCourse.price == 20){
      tabnumber -= 2;
    }
    if(selectedCourse.price == 30){
      tabnumber -= 4;
    }
    console.log(tabnumber);
    const fetchOrders = async () => {
      try {
        const date = calculateDate(tabnumber, selectedCourse.first_start_date);
        const secondDate = calculateDate(tabnumber, selectedCourse.first_end_date);
        const firstResponse = await axios.get(
          `/api/v1/order/by_course/${selectedCourse.id}?skip=0&limit=500&status=payed&start_date=${date}`,
          { headers: authHeader() }
        );
        const secondResponse = await axios.get(
          `/api/v1/order/by_course/${selectedCourse.id}?skip=0&limit=500&status=payed&start_date=${secondDate}`,
          { headers: authHeader() }
        );
  
        const combinedOrders = [...firstResponse.data, ...secondResponse.data];
        const filteredOrders = combinedOrders.filter((order: salaryOrder) => !order.is_full);
  
        const totalSum = filteredOrders.reduce((sum: number, order: salaryOrder) => {
          const percentage = selectedCourse.description * 0.01;
          return sum + order.per_amount * percentage;
        }, 0);
  
        const totalLength = filteredOrders.length;
  
        setAllorders(filteredOrders); 
        setMonthSum(totalSum);
        setOverallMonthLength(totalLength);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  
    fetchOrders();
  }, [selectedCourse, activeTab]);
  
  useEffect(() => {
    const totalsum = monthSum + yearSum;
    const totalorders = overallYearLength + overallMonthLength;
    setOverallSum(totalsum);
    setOverallLength(totalorders);
  }, [yearSum, monthSum ]);
  
  const formatNumber = (number: number) => new Intl.NumberFormat('ru-RU').format(number);
  const [orderUpdateModalActive, setOrderUpdateModalActive] = useState(false); 
  const [orderId, setOrderId] = useState<number>(0); 
  const [orderUserName, setOrderUserName] = useState<string>("");
  const [userPricelist, setUserPricelist] = useState<number>(0); 
  const openUpdateOrderModal = (userid: number, orderUserName:string, pricelist: number) => {
    setOrderId(userid);
    setOrderUserName(orderUserName);
    setUserPricelist(pricelist); 
    setOrderUpdateModalActive(true);           
  };
  return (
    <div>
      <h1 className="title">Зарплата</h1>
      <div className="top-filter">
        <select
            className="white-div__select white-div__select--big"
            onChange={handleCourseChange}
            value={selectedCourse?.id || ""}
          >
            <option value="" disabled>
              Выберите курс
            </option>
            {coursesOptions.map((course:any) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
        </select>
      </div>
      {selectedCourse ? ( 
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {selectedCourse.price == 10 ? (
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Сентябрь" value="1" />
                  <Tab label="Октябрь" value="2" />
                  <Tab label="Ноябрь" value="3" />
                  <Tab label="Декабрь" value="4" />
                  <Tab label="Январь" value="5" />
                </TabList>
                ) : selectedCourse.price == 20 ?
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Ноябрь" value="3" />
                  <Tab label="Декабрь" value="4" />
                  <Tab label="Январь" value="5" />
                </TabList> :
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Январь" value="5" />
                </TabList>
            }
          </Box>
          <TabPanel className="lesson-panel" value="1">
            <>
              <div className="summary summary-salary ">
                <p>Ученики: {overallLength}</p>
                <p>Процент: {selectedCourse.description}%</p>
                <p>Длительность: {selectedCourse.duration_month}мес.</p>
                <p>Общая сумма за предмет: {formatNumber(Number(overallSum.toFixed(0)))} KZT</p>
              </div>
              <table className="table table-fontsize">
                <tbody>
                <tr>
                  <th style={{width: '35px'}}>Id</th>
                  <th style={{width: '180px'}}>Фио</th>
                  <th style={{width: '110px'}}>Период оплаты</th>
                  <th style={{width: '110px'}}>Дата</th>
                  <th style={{width: '80px'}}>Сумма</th>
                  <th style={{width: '80px'}}>Предметы</th>
                  <th style={{width: '80px'}}>Стоимость за предмет</th>
                  <th style={{width: '80px'}}>Процент за предмет</th>
                  <th style={{width: '155px'}}>Управление</th>
                </tr>
                {yearOrders.length ? yearOrders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {((order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month).toFixed(0)} тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                {allorders.length ? allorders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {(order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)} тг 
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                </tbody>
              </table>
            </>
          </TabPanel>
          <TabPanel className="lesson-panel" value="2">
          <>
              {selectedCourse ? (
                <div className="summary summary-salary ">
                  <p>Ученики: {overallLength}</p>
                  <p>Процент: {selectedCourse.description}%</p>
                  <p>Длительность: {selectedCourse.duration_month}мес.</p>
                  <p>Общая сумма за предмет: {formatNumber(Number(overallSum.toFixed(0)))} KZT</p>
                </div>
                ) : ""
              }
              <table className="table table-fontsize">
                <tbody>
                <tr>
                  <th style={{width: '35px'}}>
                    Id
                  </th>
                  <th style={{width: '180px'}}>
                    Фио
                  </th>
                  <th style={{width: '110px'}}>
                    Период оплаты
                  </th>
                  <th style={{width: '110px'}}>
                    Дата
                  </th>
                  <th style={{width: '80px'}}>
                    Сумма
                  </th>
                  <th style={{width: '80px'}}>
                    Предметы
                  </th>
                  <th style={{width: '80px'}}>
                    Стоимость за предмет
                  </th>
                  <th style={{width: '80px'}}>
                    Процент за предмет
                  </th>
                  <th style={{width: '155px'}}>
                    Управление
                  </th>
                </tr>
                {yearOrders.length ? yearOrders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                {allorders.length ? allorders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                </tbody>
              </table>
            </>
          </TabPanel>
          <TabPanel className="lesson-panel" value="3">
          <>
              {selectedCourse ? (
                <div className="summary summary-salary ">
                  <p>Ученики: {overallLength}</p>
                  <p>Процент: {selectedCourse.description}%</p>
                  <p>Длительность: {selectedCourse.duration_month} м</p>
                  <p>Общая сумма за предмет: {formatNumber(Number(overallSum.toFixed(0)))} KZT</p>
                </div>
                ) : ""
              }
              <table className="table table-fontsize">
                <tbody>
                <tr>
                  <th style={{width: '35px'}}>
                    Id
                  </th>
                  <th style={{width: '180px'}}>
                    Фио
                  </th>
                  <th style={{width: '110px'}}>
                    Период оплаты
                  </th>
                  <th style={{width: '110px'}}>
                    Дата
                  </th>
                  <th style={{width: '80px'}}>
                    Сумма
                  </th>
                  <th style={{width: '80px'}}>
                    Предметы
                  </th>
                  <th style={{width: '80px'}}>
                    Стоимость за предмет
                  </th>
                  <th style={{width: '80px'}}>
                    Процент за предмет
                  </th>
                  <th style={{width: '155px'}}>
                    Управление
                  </th>
                </tr>
                {yearOrders.length ? yearOrders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                {allorders.length ? allorders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                </tbody>
              </table>
            </>
          </TabPanel>
          <TabPanel className="lesson-panel" value="4">
          <>
              {selectedCourse ? (
                <div className="summary summary-salary ">
                  <p>Ученики: {overallLength}</p>
                  <p>Процент: {selectedCourse.description}%</p>
                  <p>Длительность: {selectedCourse.duration_month} м</p>
                  <p>Общая сумма за предмет: {formatNumber(Number(overallSum.toFixed(0)))} KZT</p>
                </div>
                ) : ""
              }
              <table className="table table-fontsize">
                <tbody>
                <tr>
                  <th style={{width: '35px'}}>
                    Id
                  </th>
                  <th style={{width: '180px'}}>
                    Фио
                  </th>
                  <th style={{width: '110px'}}>
                    Период оплаты
                  </th>
                  <th style={{width: '110px'}}>
                    Дата
                  </th>
                  <th style={{width: '80px'}}>
                    Сумма
                  </th>
                  <th style={{width: '80px'}}>
                    Предметы
                  </th>
                  <th style={{width: '80px'}}>
                    Стоимость за предмет
                  </th>
                  <th style={{width: '80px'}}>
                    Процент за предмет
                  </th>
                  <th style={{width: '155px'}}>
                    Управление
                  </th>
                </tr>
                {yearOrders.length ? yearOrders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                {allorders.length ? allorders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Подробнее
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                </tbody>
              </table>
            </>
          </TabPanel>
          <TabPanel className="lesson-panel" value="5">
          <>
              {selectedCourse ? (
                <div className="summary summary-salary ">
                  <p>Ученики: {overallLength}</p>
                  <p>Процент: {selectedCourse.description}%</p>
                  <p>Длительность: {selectedCourse.duration_month} м</p>
                  <p>Общая сумма за предмет: {formatNumber(Number(overallSum.toFixed(0)))} KZT</p>
                </div>
                ) : ""
              }
              <table className="table table-fontsize">
                <tbody>
                <tr>
                  <th style={{width: '35px'}}>
                    Id
                  </th>
                  <th style={{width: '180px'}}>
                    Фио
                  </th>
                  <th style={{width: '110px'}}>
                    Период оплаты
                  </th>
                  <th style={{width: '110px'}}>
                    Дата
                  </th>
                  <th style={{width: '80px'}}>
                    Сумма
                  </th>
                  <th style={{width: '80px'}}>
                    Предметы
                  </th>
                  <th style={{width: '80px'}}>
                    Стоимость за предмет
                  </th>
                  <th style={{width: '80px'}}>
                    Процент за предмет
                  </th>
                  <th style={{width: '155px'}}>
                    Управление
                  </th>
                </tr>
                {yearOrders.length ? yearOrders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Обновить
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                {allorders.length ? allorders.map((order) => (
                  <tr key={order.id}>
                    <td style={{width: '35px'}}>
                      {order.id}
                    </td>
                    <td style={{width: '180px'}}>
                      {order.full_name}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.is_full == true ? "Год" : "Месяц"}
                    </td>
                    <td style={{width: '110px'}}>
                      {order.start_date} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.total_amount} 
                    </td>
                    <td style={{width: '90px'}}>
                      {order.order_position_count}
                    </td>
                    <td style={{width: '90px'}}>
                      {order.per_amount} тг
                    </td>
                    <td style={{ width: '90px' }}>
                      {order.is_full
                        ? (
                            (order.per_amount * (selectedCourse.description * 0.01)) / selectedCourse.duration_month
                          ).toFixed(0)
                        : (order.per_amount * (selectedCourse.description * 0.01)).toFixed(0)
                      } тг
                    </td>
                    <td style={{width: '155px'}}>
                      <div className="item-control">
                        <div onClick={() => openUpdateOrderModal(order.id, order.full_name, order.pricelist || 0)}  className="item-control__item">
                          <i className="fa-solid fa-pen"></i> Обновить
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : "Загрузка"}
                </tbody>
              </table>
            </>
          </TabPanel>
        </TabContext>) : "Выберите курс"
      }
      
      {orderUpdateModalActive && orderId !== null && (
        <ModalOrderView
          active={orderUpdateModalActive}
          orderId={orderId}
          orderUsername={orderUserName}
          userPricelist={userPricelist}
          setActive={setOrderUpdateModalActive}
        />
      )}
    </div>
  );
};
