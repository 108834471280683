import axios from "axios";
import { FC, useState } from "react";
import Select from "react-select";
import { useMemo } from "react";
import { AdminUser, ICourse,  prePaidOrder, PriceListOption, OrderPosition} from "src/interface";
import { authHeader } from "src/utils/authHeader";

import "./AddCourse.scss";
export const AddCourse: FC<{
  coursesList: ICourse[];
  user: AdminUser;
}> = ({ user, coursesList }) => {
  const [sended, setSended] = useState<boolean>(false);
  const [potokSelected, setPotokSelected] = useState<PriceListOption>(
    { value: 3, label: "Поток 3" }
  );
  const potokOptions: any = [
    { value: 1, label: "Поток 1" },
    { value: 2, label: "Поток 2" },
    { value: 3, label: "Поток 3" },
  ];  
  const [order, setOrder] = useState<prePaidOrder>({
    start_date: "",
    user_id: 0,
    end_date: "",
    is_full: false,
    package: "standard",
    status: "payed",
    positions: [],
  });
  const priceMap: Record<number, number> = {
    1: 10,
    2: 20,
    3: 30,
  };
  const filteredCourses = useMemo(() => {
    const potokPrice = priceMap[potokSelected.value];
    return coursesList.filter((course) => course.price === potokPrice);
  }, [coursesList, potokSelected]);
  
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Check for required fields
    if (
      !order.start_date ||
      !order.end_date ||
      order.positions.length === 0
    ) {
      alert("заполните все данные");
      return;
    }
  
    // Prevent duplicate submissions
    if (sended) {
      alert("Платеж уже отправлен");
      return;
    }
  
    // Send the request
    axios
      .post("/api/v1/order/prepaid/" + user.id + "/", order, { headers: authHeader() })
      .then((response) => {
        if (response.status === 200) {
          setSended(true);
          alert("Платеж создан");
          location.reload();
        }
      })
      .catch((error) => {
        alert(error.response?.data || "Произошла ошибка");
      });
  };
  // const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const element = e.target as HTMLInputElement;
  //   const value = element.value;
  //   const name = element.name;
  //   if (name === "created_at") {
  //     const isoDate = new Date(value).toISOString(); // Convert to ISO format
  //     setOrder((prev) => ({ ...prev, [name]: isoDate }));
  //   } else {
  //     setOrder((prev) => ({ ...prev, [name]: value }));
  //   }
  // };
  const handleCheckboxChange = (course: ICourse, checked: boolean): void => {
    if (checked) {
      const newPosition: OrderPosition = {
        course_id: course.id,
        start_date: order.start_date || "",
        end_date: order.end_date || "",
      };
      setOrder((prev) => ({
        ...prev,
        positions: [...prev.positions, newPosition],
      }));
    } else {
      setOrder((prev) => ({
        ...prev,
        positions: prev.positions.filter((position) => position.course_id !== course.id),
      }));
    }
  };
  console.log(order);
  return (
    <>
      <div>
        <div className="add-course-wrapper">
          <form action="" onSubmit={onSubmit}>
            <div className="les-row">
              <label className="les-row__name">Период:</label>
              <div className="flex">
                <ul className="course-elements">
                  <li className="course-elem">
                    <input
                      type="checkbox"
                      id="february"
                      checked={order.end_date === "2025-03-19"}
                      name="february"
                      value="01"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOrder((prev) => ({
                            ...prev,
                            start_date: "2025-02-20",
                            end_date: "2025-03-19",
                          }));
                        } else {
                          setOrder((prev) => ({
                            ...prev,
                            start_date: "",
                            end_date: "",
                          }));
                        }
                      }}
                    />
                    <label htmlFor="february">Февраль</label>
                  </li> 
                  <li className="course-elem">
                  <input
                    type="checkbox"
                    id="year"
                    checked={order.end_date === "2025-05-19"}
                    name="year"
                    value="01"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOrder((prev) => ({
                          ...prev,
                          start_date: "2025-01-20",
                          end_date: "2025-05-19",
                        }));
                      } else {
                        setOrder((prev) => ({
                          ...prev,
                          start_date: "",
                          end_date: "",
                        }));
                      }
                    }}
                  />
                  <label htmlFor="year">Весь год</label>
                </li>   
                </ul>
                {/* <div className="date-row">
                  <input onChange={onChange} name="start_date" type="date"></input>
                </div>
                <div className="date-row">
                  <input onChange={onChange} name="end_date" type="date"></input>
                </div> */}
              </div>
            </div>
            <div className="les-row">
              <label className="les-row__name">Пакет:</label>
              <label className="les-radio">
                <input
                  type="radio"
                  name="package"
                  checked={order.package === "standard"}
                  value="standard"
                  onChange={() => setOrder((prev) => prev && { ...prev, package: "standard" })}
                />
                Про
              </label>
              <label className="les-radio">
                <input
                  type="radio"
                  name="package"
                  checked={order.package === "premium"}
                  value="premium"
                  onChange={() => setOrder((prev) => prev && { ...prev, package: "premium" })}
                />
                Премиум
              </label>
            </div>
            <div className="les-row">
              <label className="les-row__name">Тип оплаты:</label>
              <label className="les-radio">
                <input
                  type="radio"
                  name="is_full"
                  checked={!order.is_full}
                  value="false"
                  onChange={() => setOrder((prev) => prev && { ...prev, is_full: false })}
                />
                Месяц
              </label>
              <label className="les-radio">
                <input
                  type="radio"
                  name="is_full"
                  checked={order.is_full}
                  value="true"
                  onChange={() => setOrder((prev) => prev && { ...prev, is_full: true })}
                />
                Год
              </label>
            </div>
            <div className="les-row">
              <label className="les-row__name">Поток:</label>
              <Select
                  value={potokSelected}
                  options={potokOptions} 
                  onChange={(option: any) => {
                    setPotokSelected(option);
                  }}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      minWidth: '170px',
                    }),
                  }}
                />             
            </div>
            <div className="les-row les-row--flex-top">
              <label className="les-row__name">Курсы:</label>
                <ul className="course-elements">
                {filteredCourses.map((course) => (
                  <li key={course.id} className="course-elem">
                     <input
                      type="checkbox"
                      disabled={order.start_date === "" || order.end_date === ""}
                      onChange={(e) => handleCheckboxChange(course, e.target.checked)}
                      id={course.name}
                      name={course.name}
                    />
                    <label htmlFor={course.name}>{course.name}</label>
                  </li> 
                ))}
              </ul>
            </div>    
            
            <button type="submit" disabled={sended} className="btn btn-primary btn-add-course">
              {sended ? "Отправлено" : "Добавить платежку"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
