import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import React, { useRef } from "react";

interface QuestionCardProps {
  id: any;
  onDeleteQuestion: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeChoice: (e: React.MouseEvent<HTMLInputElement>) => void;
  onUploadPhoto: (event: React.ChangeEvent<HTMLInputElement>) => void;
  question: any;
  index: number;
  onUpdateChoice: (choice: any) => void;
  testQuqestions: any[];
  onUpdateQuestion: (question: any) => void;
  onUploadQuestionPhoto: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTestQuqestions: React.Dispatch<React.SetStateAction<any>>;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onUpdateQuestionSequence: (question: any, sq: number) => Promise<void>;
}

export const ItemTypes = {
  CARD: "card",
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export default function QuestionCard({
  onDeleteQuestion,
  question,
  index,
  onChangeChoice,
  onUploadPhoto,
  onUpdateChoice,
  testQuqestions,
  onUpdateQuestion,
  onUploadQuestionPhoto,
  setTestQuqestions,
  moveCard,
  id,
  onUpdateQuestionSequence,
}: QuestionCardProps) {
  const ref = useRef<HTMLLIElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (draggedItem) => {
      console.log(question, draggedItem, id, testQuqestions);
      const target = testQuqestions.find(
        (t) => t.question.sequence === draggedItem.index + 1
      );
      console.log(target);
      if (target) {
        onUpdateQuestionSequence(
          target.question.id,
          question.question.sequence
        );
      }
      onUpdateQuestionSequence(id, draggedItem.index + 1);
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const onChangeInput = (e: any) => {
    const newQuestions = [...testQuqestions];
    newQuestions[index].question.display_name = e.target.value;
    setTestQuqestions(newQuestions);
  };

  const onBlurInput = () => {
    onUpdateQuestion(question.question);
  };

  return (
    <li
      ref={ref}
      key={question.id}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className="question-admin">
        <div className="question-admin__heading">
          <div className="question-admin__text">
            {index + 1 + ")"}
            <input
              value={question.question.display_name}
              onChange={onChangeInput}
              onBlur={onBlurInput}
              type="text"
            />
            <div className="question-admin__file">
              <i className="fa-regular fa-image"></i>
              <input
                id={"" + question.question.id}
                onChange={onUploadQuestionPhoto}
                accept="image/png, image/jpeg"
                type="file"
              ></input>
            </div>
          </div>
          {question.question.image !== null ? (
            <div className="realadmin-choice-row__img">
              <img
                src={"https://app.easyent.kz" + question.question.image}
              ></img>
            </div>
          ) : (
            ""
          )}
        </div>
        {question.question.question_type == "choice" ? (
          <div className="question-row__area">
            {question["question"]["variants"].length > 0 ? (
              question["question"]["variants"]
                .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                .map((choice: any, choiceIdx: any) => (
                  <div key={choice.id} className="admin-choice-row">
                    <div className="admin-choice">
                      {choice.is_correct ? (
                        <div className="tipo-input">
                          <i className="fa-solid fa-check"></i>
                        </div>
                      ) : (
                        <div
                          data-sequence={index + 1}
                          data-value={choice.value}
                          data-quesiton-id={"" + question.id}
                          onClick={onChangeChoice}
                          className="tipo-input"
                        ></div>
                      )}
                      <div className="admin-choise__right">
                        <label htmlFor={"" + choice.id}>
                          <input
                            value={choice.value}
                            onChange={(e) => {
                              const newQuestions = [...testQuqestions];
                              newQuestions[index].question.variants[
                                choiceIdx
                              ].value = e.target.value;
                              setTestQuqestions(newQuestions);
                            }}
                            onBlur={() => {
                              onUpdateChoice(choice);
                            }}
                            type="text"
                          />
                        </label>
                        <div className="question-admin__file">
                          <i className="fa-regular fa-image"></i>
                          <input
                            id={"" + choice.id}
                            onChange={onUploadPhoto}
                            accept="image/png, image/jpeg"
                            type="file"
                          ></input>
                        </div>
                      </div>
                    </div>
                    {choice.image ? (
                      <div className="adminreal-choice-row__img">
                        <img
                          src={"https://app.easyent.kz/" + choice.image}
                        ></img>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))
            ) : (
              <div>пусто </div>
            )}
          </div>
        ) : (
          <table className="group-table">
            {question["question"]["variants"].length > 7 ? (
              <>
                <tr>
                  <td>
                    {question["question"]["variants"]
                      .filter((item: any) => item.name == "value1")
                      .map((choice: any) => (
                        <p key={choice.group_name}>{choice.group_name}</p>
                      ))}
                  </td>
                  <td>
                    <ul className="group-ul">
                      {question["question"]["variants"]
                        .sort(
                          (a: any, b: any) =>
                            a?.name.substring(5, 6) - b?.name.substring(5, 6)
                        )
                        .slice(0, 4)
                        .map((choice: any) => (
                          <li key={choice.id}>
                            <div className="admin-choice">
                              {choice.is_correct ? (
                                <div className="tipo-input">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              ) : (
                                <div
                                  onClick={onChangeChoice}
                                  data-sequence={index + 1}
                                  data-value={choice.value}
                                  data-quesiton-id={"" + question.id}
                                  className="tipo-input"
                                ></div>
                              )}
                              <div className="admin-choise__right">
                                <label htmlFor="124">{choice.value}</label>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    {question["question"]["variants"]
                      .filter((item: any) => item.name == "value8")
                      .map((choice: any) => (
                        <p key={choice.group_name}>{choice.group_name}</p>
                      ))}
                  </td>
                  <td>
                    <ul className="group-ul">
                      {question["question"]["variants"]
                        .sort(
                          (a: any, b: any) =>
                            a?.name.substring(5, 6) - b?.name.substring(5, 6)
                        )
                        .slice(4, 8)
                        .map((choice: any) => (
                          <li key={choice.id}>
                            <div className="admin-choice">
                              {choice.is_correct ? (
                                <div className="tipo-input">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              ) : (
                                <div
                                  data-sequence={index + 1}
                                  data-value={choice.value}
                                  data-quesiton-id={"" + question.id}
                                  className="tipo-input"
                                ></div>
                              )}
                              <div className="admin-choise__right">
                                <label htmlFor="124">{choice.value}</label>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </td>
                </tr>
              </>
            ) : (
              "Ошибка"
            )}
          </table>
        )}
        <button
          data-quesiton-id={"" + question.question.id}
          onClick={onDeleteQuestion}
          className="btn btn-primary btn-inline"
        >
          Удалить вопрос
        </button>
      </div>
    </li>
  );
}
