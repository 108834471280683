import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { authHeader } from "src/utils/authHeader";
import { useParams, Link } from "react-router-dom";
import { AddTestQuestion } from "src/components";
import Select from "react-select";
import "./AdminTestInner.scss";
import { DndProvider } from "react-dnd";
import QuestionCard from "../../components/QuestionCard/QuestionCard";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";

export const AdminTestInner = () => {
  document.title = "Тесты | EasyENT";
  const params = useParams();
  const [testQuestions, setTestQuestions] = useState<any>([]);
  const [uploadPhoto, setUploadPhoto] = useState({ photofile: "string" });
  const [subjectTitle, setSubjectTitle] = useState<string>("");
  const [subjectValue, setSubjectValue] = useState<any>({
    value: 1,
    label: "История Казахстана",
  });
  const [newTest, setNewTest] = useState<any>({
    subject_id: 1,
    lang: "ru",
    write_date: "2024-01-22T11:36:21.623Z",
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post("/api/v1/tests/tests/", newTest, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
          alert("Тест редактирован");
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  useEffect(() => {
    axios
      .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader() })
      .then((response: any) => {
        const sorted = [...response.data.questions].sort(
          (a, b) => a.question.sequence - b.question.sequence
        );
        setTestQuestions(sorted);
      });
    axios
      .get(`/api/v1/tests/tests/${params.id}`, { headers: authHeader() })
      .then((response: any) => {
        setSubjectTitle(response.data.subject.title);
      });
  }, [uploadPhoto]);

  const UploadQuestionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post("/api/v1/tests/question/" + question_id + "/image", formdata, {
          headers: authHeader(),
        })
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };
  const onUpdateQuestion = (question: any) => {
    const newQuestion = {
      display_name: question.display_name,
    };
    axios
      .put("/api/v1/tests/question/" + question.id, newQuestion, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };
  const onUpdateChoice = (choice: any) => {
    axios
      .put("/api/v1/tests/variant/" + choice.id, choice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };
  const getLessonQuestions = () => {
    axios
      .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader() })
      .then((response) => {
        const sorted = [...response.data.questions].sort(
          (a, b) => a.question.sequence - b.question.sequence
        );
        setTestQuestions(sorted);
        // setPrevQuestions(JSON.parse(JSON.stringify(sorted)));
      });
  };
  const onChangeChoiceValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const questionIndex = element.getAttribute("data-sequence");
    const questionIndexN = parseInt("" + questionIndex);
    const choidId = element.getAttribute("data-id");
    const choiceIdN = parseInt("" + choidId);
    const variants = testQuestions[questionIndexN].question.variants;
    const newChoice = variants.find((choice: any) => choice.id === choiceIdN);
    let length_iscorrect = 0;
    for (let index = 0; index < variants.length; index++) {
      if (variants[index].is_correct === true) {
        console.log(variants[index].value);
        length_iscorrect += 1;
      }
    }
    console.log(length_iscorrect);
    if (length_iscorrect < 2) {
      newChoice.is_correct = true;
      axios
        .put("/api/v1/tests/variant/" + choiceIdN, newChoice, {
          headers: authHeader(),
        })
        .then(() => {
          getLessonQuestions();
        });
    } else {
      alert("больше 2 вариантов нельзя");
    }
  };
  // const ChoiceValueToFalse = (e: React.MouseEvent<HTMLInputElement>) => {
  //   const element = e.currentTarget;
  //   const questionIndex = element.getAttribute("data-sequence");
  //   const questionIndexN = parseInt("" + questionIndex);
  //   const choidId = element.getAttribute("data-id");
  //   const choiceIdN = parseInt("" + choidId);
  //   const variants = testQuestions[questionIndexN].question.variants;
  //   const newChoice = variants.find((choice: any) => choice.id === choiceIdN);
  //   newChoice.is_correct = false;
  //   axios
  //     .put("/api/v1/tests/variant/" + choiceIdN, newChoice, {
  //       headers: authHeader(),
  //     })
  //     .then(() => {
  //       getLessonQuestions();
  //     });
  // };
  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_choise_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/tests/variant/" + question_choise_id + "/image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };

  const onUpdateQuestionSequence = async (
    questionId: number,
    sequence: number
  ) => {
    const newQuestion = {
      sequence,
    };
    await axios
      .put("/api/v1/tests/question/" + questionId, newQuestion, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setTestQuestions((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((question: any, index: number, t: any) => {
    return (
      <QuestionCard
        index={index}
        question={question}
        key={question.id}
        id={question.question.id}
        moveCard={moveCard}
        onChangeChoice={onChangeChoiceValue}
        onUploadPhoto={UploadPhoto}
        onDeleteQuestion={DeleteQuestion}
        onUpdateChoice={onUpdateChoice}
        testQuqestions={t}
        setTestQuqestions={setTestQuestions}
        onUpdateQuestion={onUpdateQuestion}
        onUploadQuestionPhoto={UploadQuestionPhoto}
        onUpdateQuestionSequence={onUpdateQuestionSequence}
      />
    );
  }, []);

  const languageOptions: any = [
    { value: "ru", label: "Русский язык" },
    { value: "kz", label: "Казахский язык" },
  ];

  const DeleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget;
    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    axios
      .delete("/api/v1/tests/question/" + questionIdnumber, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 204 || response.status == 200) {
          alert("Вопрос успешно удален");
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    // const name = element.name;
    setNewTest((s: any) => ({ ...s, ["write_date"]: value }));
    console.log(newTest);
  };

  const kzsubjectOptions: any = [
    { value: 232, label: "Қазақстан тарихы" },
    { value: 265, label: "Математикалық сауаттылық" },
    { value: 317, label: "Математика" },
    { value: 336, label: "Химия" },
    { value: 377, label: "Биология" },
    { value: 399, label: "География" },
    { value: 422, label: "Дүниежүзі тарихы" },
  ];

  const subjectOptions: any = [
    { value: 1, label: "История Казахстана" },
    { value: 5, label: "Мат.Грамотность" },
    { value: 10, label: "Математика" },
    { value: 23, label: "Физика" },
    { value: 29, label: "Химия" },
    { value: 48, label: "Биология" },
    { value: 91, label: "География" },
    { value: 100, label: "Всемирная История" },
    { value: 110, label: "Английский язык" },
    { value: 121, label: "Информатика" },
  ];

  const [languageOption, setLanguageOption] = useState<any>({
    value: "ru",
    label: "Русский язык",
  });

  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои тесты</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/admin/tests/${params.id}`}>{subjectTitle}</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>Редактирование теста</span>
        </li>
      </ul>
      <h1 className="title"></h1>
      <form onSubmit={onSubmit} className="add-dz box">
        <div className="form-row form-row--main">
          <label>
            <b>Язык теста:</b>
          </label>
          <Select
            defaultValue={languageOption}
            options={languageOptions}
            onChange={(option: any) => {
              setLanguageOption(option);
            }}
            name="display_name"
          />
        </div>
        <div className="form-row form-row--main">
          <label>
            <b>Предмет:</b>
          </label>
          <Select
            defaultValue={subjectValue}
            options={
              languageOption.value == "ru" ? subjectOptions : kzsubjectOptions
            }
            onChange={(option: any) => {
              if (option)
                setNewTest((item: any) => ({
                  ...item,
                  subject_id: option.value,
                }));
              setSubjectValue(option);
            }}
            name="display_name"
          />
        </div>
        <div className="form-row form-row--main">
          <label>
            <b>Дата:</b>
          </label>
          <input
            required
            name="start_date"
            type="datetime-local"
            onChange={onChange}
          />
        </div>

        <button className="btn btn-primary" type="submit">
          Редактировать
        </button>
      </form>
      <AddTestQuestion
        questionlength={testQuestions.length ? testQuestions.length : "0"}
        testid={params.id}
      />
      <h1>Cписок вопросов:</h1>
      <ul className="qadmin-list">
        <DndProvider backend={HTML5Backend}>
          {testQuestions.map((question: any, index: any) =>
            renderCard(question, index, testQuestions)
          )}
        </DndProvider>
      </ul>
    </div>
  );
};
