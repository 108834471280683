import axios from "axios";
import React, { useEffect, useState } from "react";
import { newOrder, orderStatus, orderStatus2 } from "src/interface";
import { Link } from "react-router-dom";
import Select from 'react-select';
import "./AdminOrderPage.scss";
import { authHeader } from "src/utils/authHeader";
import { ModalOrderUpdate } from "src/components";
import { ModalOrderChange } from "src/components";

export const AdminOrdersPage = () => {
  document.title = "Ордера | EasyENT";
  const [allorders, setAllorders] = useState<newOrder[]>([]);
  const [orderStatus, setOrderStatus] = useState<orderStatus>({
    value:"draft", label: 'На оплату'
  });
  const [dateOption, setDateOption] = useState<any>({
    value: 'Февраль', date1: '2025-02-09', date2: '2025-02-11', label: 'Февраль', date3: '2025-02-20',
  });
  const orderoptions:orderStatus[] = [
    { value: 'draft', label: 'На оплату' },
    { value: 'payed', label: 'Оплачено' },
    { value: 'canceled', label: 'Отменено' },
  ];
  const dateoptions:orderStatus2[] = [
    { value: 'Сентябрь', date1: '2024-09-09', date2: '2024-09-11', date3: '2024-09-20', label: 'Сентябрь' },
    { value: 'Октябрь', date1: '2024-10-09', date2: '2024-10-11',  date3: '2024-10-20',  label: 'Октябрь' },
    { value: 'Ноябрь', date1: '2024-11-09', date2: '2024-11-11',  date3: '2024-11-20', label: 'Ноябрь' },
    { value: 'Декабрь', date1: '2024-12-09', date2: '2024-12-11',  date3: '2024-12-20', label: 'Декабрь' },
    { value: 'Январь', date1: '2025-01-09', date2: '2025-01-11',  date3: '2025-01-20', label: 'Январь' },
    { value: 'Февраль', date1: '2025-02-09', date2: '2025-02-11',  date3: '2025-02-20', label: 'Февраль' },
  ];
  const [overallSum, setOverallSum] = useState<number>(0);
  const [overallLength, setOverallLength] = useState<number>(0);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const date1 = dateOption.date1;
        const date2 = dateOption.date2;
        const date3 = dateOption.date3;

        const [response1, response2, response3] = await Promise.all([
          axios.get(
            `/api/v1/order/?skip=0&limit=500&status=${orderStatus.value}&start_date=${date1}`,
            { headers: authHeader() }
          ),
          axios.get(
            `/api/v1/order/?skip=0&limit=500&status=${orderStatus.value}&start_date=${date2}`,
            { headers: authHeader() }
          ),
          axios.get(
            `/api/v1/order/?skip=0&limit=500&status=${orderStatus.value}&start_date=${date3}`,
            { headers: authHeader() }
          ),
        ]);
        const combinedOrders = [...response1.data, ...response2.data, ...response3.data];
        setAllorders(combinedOrders);
        const totalSum = combinedOrders.reduce((sum: number, order: newOrder) => {
          return sum + (isNaN(order.total_amount) ? 0 : Number(order.total_amount));
        }, 0);
        const totalLength = combinedOrders.length;

        setOverallSum(totalSum);
        setOverallLength(totalLength);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      }
    };

    fetchOrders();
  }, [orderStatus,dateOption]);

  const formatNumber = (number: number) => new Intl.NumberFormat('ru-RU').format(number);
  const [orderUpdateModalActive, setOrderUpdateModalActive] = useState(false); 
  const [orderChangeModalActive, setOrderChangeModalActive] = useState(false); 
  const [orderId, setOrderId] = useState<number>(0); 
  const [orderUserName, setOrderUserName] = useState<string>("");
  const [userPricelist, setUserPricelist] = useState<number>(0); 
  const openUpdateOrderModal = (userid: number, orderUserName:string, pricelist: number) => {
    setOrderId(userid);
    setOrderUserName(orderUserName);
    setUserPricelist(pricelist); 
    setOrderUpdateModalActive(true);           
  };
  const openChangeOrderModal = (userid: number, orderUserName:string, pricelist: number) => {
    setOrderId(userid);
    setOrderUserName(orderUserName);
    setUserPricelist(pricelist); 
    setOrderChangeModalActive(true);           
  };
  return (
    <div>
      <h1 className="title">Платежки</h1>
      <div className="box top-div">
        <div className="select-part">
          <div className="select-part__left">
            <div className="select-part__item">
              <p>Период</p>
              <Select
                  value={dateOption}
                  options={dateoptions}
                  onChange={(option: any) => {
                    if(option) setDateOption(option)
                    }
                  } 
              />
            </div>
            <div className="select-part__item">
              <p>Вид оплаты</p>
              <Select
                  value={orderStatus}
                  options={orderoptions}
                  onChange={(option: any) => {
                    if (option) setOrderStatus(option)
                  }} 
                />    
            </div>
          </div>
          <Link to="payment" className="btn btn-primary btn-inline">Выставить платежку</Link>
        </div>
        <div className="summary">
            <p>Общее количество заказов: {overallLength}</p>
            <p>Общая сумма: {formatNumber(overallSum)} KZT</p>
        </div>
      </div>
      
      <table className="table table-fontsize">
        <tbody>
        <tr>
          <th style={{width: '35px'}}>
            Id
          </th>
          <th style={{width: '45px'}}>
            us.id
          </th>
          <th style={{width: '220px'}}>
            Фио
          </th>
          <th style={{width: '150px'}}>
            Номер
          </th>          
          <th style={{width: '200px'}}>
            Почта
          </th>
          <th style={{width: '100px'}}>
            Период
          </th>
          <th style={{width: '100px'}}>
            Cтатус
          </th>
          <th style={{width: '100px'}}>
            Дата
          </th>
          <th style={{width: '70px'}}>
            Сумма
          </th>
          <th style={{width: '155px'}}>
            Управление
          </th>
        </tr>
        {allorders.length ?  allorders.map((order) => (
          <tr key={order.id}>
            <td style={{width: '35px'}}>
              {order.id}
            </td>
            <td style={{width: '45px'}}>
              {order.user.id}
            </td>
            <td style={{width: '220px'}}>
              {order.user.full_name}
            </td>
            <td style={{width: '150px'}}>
              {order.user.phone}
            </td>
            <td style={{width: '200px'}}>
              {order.user.email}
            </td>
            <td style={{width: '100px'}}>
              {order.is_full == true ? "Год" : "Месяц"}
            </td>
            <td style={{width: '100px'}}>
              {order.status == "draft" ? "на оплату" : order.status == "payed" ? "оплачено" : "отказ"}
            </td>
            <td style={{width: '100px'}}>
              {order.start_date} 
            </td>
            <td style={{width: '90px'}}>
              {order.total_amount} 
            </td>
            <td style={{width: '155px'}}>
              <div className="item-control">
                <div onClick={() => openUpdateOrderModal(order.id, order.user.full_name, order.user.pricelist)}  className="item-control__item">
                  <i className="fa-solid fa-pen"></i> Обновить
                </div>
                <div onClick={() => openChangeOrderModal(order.id, order.user.full_name, order.user.pricelist)}  className="item-control__item">
                  <i className="fa-solid fa-pen"></i> Изменить
                </div>
              </div>
            </td>
          </tr>
        )) : "Загрузка"}
        </tbody>
      </table>
      {orderUpdateModalActive && orderId !== null && (
        <ModalOrderUpdate
          active={orderUpdateModalActive}
          orderId={orderId}
          orderUsername={orderUserName}
          userPricelist={userPricelist}
          setActive={setOrderUpdateModalActive}
        />
      )}
      {orderChangeModalActive && orderId !== null && (
        <ModalOrderChange
          active={orderChangeModalActive}
          orderId={orderId}
          orderUsername={orderUserName}
          userPricelist={userPricelist}
          setActive={setOrderChangeModalActive}
        />
      )}
    </div>
  );
};
